@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
}

@keyframes gradient-x {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

@keyframes drawUnderline {
  to {
    stroke-dashoffset: 0;
  }
}

.animate-gradient-x {
  background-size: 200% 200%;
  animation: gradient-x 15s ease infinite;
}

.hover\:animate-wiggle:hover {
  animation: wiggle 0.3s ease-in-out infinite;
}

.animate-fade-in-down { animation: fadeInDown 0.5s ease-out; }
.animate-fade-in-up { animation: fadeInUp 0.5s ease-out; }
.animate-fade-in { animation: fadeIn 0.5s ease-out; }

.hover\:rotate-360:hover { transform: rotate(360deg); }

body {
  font-family: 'Inter', sans-serif;
  background-color: theme('colors.background');
  color: theme('colors.primary');
}

.cta-button {
  @apply bg-secondary text-background font-bold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300 text-lg;
}

.bg-gradient-recenstar {
  background: linear-gradient(to bottom, #1a202c, #2d3748);
}

.text-gradient-recenstar {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.card-hover {
  transition: all 0.3s ease;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.youtube-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
  max-width: 1280px; /* Larghezza massima su schermi grandi */
  margin: 0 auto; /* Centra il contenitore */
}

.youtube-container iframe,
.youtube-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.group:hover svg path {
  animation: drawUnderline 0.3s ease-in-out forwards;
}

.group svg path {
  stroke-dasharray: 150;
  stroke-dashoffset: 150;
}

/* Stili per PhoneInput */
.react-tel-input .country-list {
  background-color: #1E1E1E !important;
  color: white !important;
}

.react-tel-input .country-list .country:hover {
  background-color: #333 !important;
}

.react-tel-input .country-list .country-name {
  color: white !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #333 !important;
}

.react-tel-input .form-control {
  background-color: #1E1E1E !important;
  color: white !important;
  border-color: #4a4a4a !important;
}

.react-tel-input .selected-flag {
  background-color: #1E1E1E !important;
  border-color: #4a4a4a !important;
}

.react-tel-input .country-list .country {
  padding: 7px 10px !important;
}

.react-tel-input .country-list .country .dial-code {
  color: #FAD403 !important;
}

.react-tel-input .flag-dropdown {
  background-color: #1E1E1E !important;
  border: 1px solid #4a4a4a !important;
}

.react-tel-input .flag-dropdown.open {
  background-color: #1E1E1E !important;
  border: 1px solid #4a4a4a !important;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none !important;
  border-bottom: 4px solid #fff !important;
}

@media (max-width: 768px) {
  #benefits .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #benefits h2 {
    font-size: 2rem;
  }

  #benefits p {
    font-size: 1rem;
  }

  #benefits .bg-gray-900 {
    padding: 1rem;
  }

  #reasons h2 {
    font-size: 2rem;
  }

  #reasons p {
    font-size: 1rem;
  }

  #reasons .youtube-container {
    margin-bottom: 2rem; /* Aumenta lo spazio sotto il video su mobile */
  }

  #reasons .cta-button {
    margin-top: 1rem; /* Aggiunge spazio sopra il bottone su mobile */
  }
}

@media (min-width: 768px) {
  .youtube-container {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .youtube-container {
    width: 70%; /* Riduce ulteriormente la larghezza su schermi ancora più grandi */
  }
}

html {
  scroll-behavior: smooth;
}